const configSources = [
    {
      "key": "modeler",
      "logoclass": "panel-card-header--logo modeler",
      "logoimg": "/images/bzg-bizagi-modeler-white.png",
      "title": "Modeler Help",
      "url": "https://help.bizagi.com/process-modeler"
    },
    {
      "key": "studio",
      "logoclass": "panel-card-header--logo studio",
      "logoimg": "/images/bzg-bizagi-studio-white.png",
      "title": "Studio Help",
      "url": "https://help.bizagi.com/bpm-suite"
    },
    {
      "key": "automation",
      "logoclass": "panel-card-header--logo automation",
      "logoimg": "/images/bzg-bizagi-studio-white.png",
      "title": "Automation Help",
      "url": "https://help.bizagi.com/automation-service/en/"
    }
  ];

  export default configSources;