import React from 'react';
import configSources from './config'

const OtherSources = () =>{
    return(
        configSources.map((item) =>(
            <div class="col-xs-12 col-sm-12 col-md-12">
            <section class="panel-card bz-space--stack-l">
            <div class="panel-card-header">
            <img  alt={item.title} class={item.logoclass} src={item.logoimg} />
            <span class="panel-card-header-text">
            <b  class="panel-card-header-text--title">{item.title}</b>
            <a  class="bz-font-size--body-small" rel="noopener" target="_blank" href={item.url}> Click here </a>
            </span>
            </div>
            </section>
            </div>
            ))
            )
        };
        
export default OtherSources;